/**
 * @Description:
 * @author Guan LinTao
 * @date 6:20 下午
 * 模版生成器
*/

class Generator {
  constructor(data) {

    // 基本信息
    this.activeData = {
      activityName: '',
      startTime: '',
      endTime: '',
      activityRemark: '',
      templateCode: '',
      templateComKey: ''
    }

    // 模板配置信息
    this.configData = data || {
      navigationShow: 1,
      navigationTitle: '',
      navigationFontColor: '#ffffff',
      navigationBackgroundColor: '#000000',
      backgroundColor: '#ffffff',
      backgroundImg: []
    }
  }

  // 初始化
  initConfigData(mode, config) {
    this.activeData = config

    // 模板配置
    if (config.ruleConfigMap) {
      this.configData = config.ruleConfigMap
    }
    // 创建初始化赋值
    if (mode === 0) {
      this.configData = {
        ...this.configData,
        ...config.defaultTemplate,
        navigationTitle: config.activityName
      }
    }
  }

}

export default Generator
