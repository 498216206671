<!--活动配置-->
<template>
  <div class="active-info" v-loading="pageLoading">

    <div class="info-content">

      <!--预览-->
      <templatePreview :generator="generator"></templatePreview>

      <!--数据配置组件-->
      <templateContainer :generator="generator" :activityInfo="activityInfo" @updateTemplate="updateTemplate"></templateContainer>

    </div>

  </div>
</template>

<script>
// 模板生成器
import Generator from '@/utils/generator'
// 预览组件
import templatePreview from './templatePreview'
// 数据配置组件
import templateContainer from './templateContainer'

export default {
  props: {
    activityInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    templatePreview,
    templateContainer
  },
  name: 'activeConfig',
  data() {
    return {
      editMode: 0,
      generator: new Generator,
      pageLoading: false
    }
  },
  mounted() {
    this.initTemplate()
  },
  methods: {

    // 初始化模板
    initTemplate() {
      this.editMode = this.$route.query.editMode || 0
      this.generator.initConfigData(this.editMode, this.activityInfo)
    },

    updateTemplate(data) {
      const { info, status } = data
      this.$emit('active', {
        status: status,
        info: info
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .active-info{
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
    padding-right: 12px;
    position: relative;
    .info-content{
      width: 100%;
      height: 100%;
      padding-top: 12px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
    }
    ::v-deep .el-form{
      width: 60%;
      margin-bottom: 40px;
      .el-input, .el-date-editor, .el-textarea{
        width: 60%;
      }
    }
  }
</style>
