export const templateComKey = {
  'SHOP_SHOW_1': 'shopShow',
  'SHOP_SHOW_2': 'sidebarModel',
  'SHOP_SHOW_3': 'cardModel'
}

// 模板默认配置信息
export const templateDefaultData = {
  'SHOP_SHOW_1': {
    'shopShowConfig': [],
    'firstImg': '',
    'caseDetailShowType': 'tiling'
  },
  'SHOP_SHOW_2': {
    'shopShowConfig': [],
    'firstImg': ''
  },
  'SHOP_SHOW_3': {
    'shopShowConfig': [],
    'shopBanner': [],
    'shopTag': []
  }
}
