<!--创建活动-->
<template>
  <div class="create-activity" v-loading="pageLoad">

    <!--创建流程-->
    <Steps :active="currentStep" class="active-step" align-center>
      <Step v-for="(item, index) in stepConfig[editMode]" :key="index" :description="item" />
    </Steps>

    <!--基础信息-->
    <div v-if="currentStep === 1" class="basic-info">
      <templateActiveConfig :activeData="activityInfo" />
      <el-button type="primary" @click="openTemplate">下一步</el-button>
    </div>

    <!--模版列表-->
    <activeTemplate class="active-com" v-if="currentStep === 2" @startMake="selectedTemplate" />

    <!--活动配置-->
    <activeConfig class="active-com" v-if="currentStep === 3" :activityInfo="activityInfo" @active="activeCall" />

    <!--预览二维码-->
    <previewActive class="active-com" v-if="currentStep === 4" :info="previewActiveInfo" @createActive="createActive" />

  </div>
</template>

<script>
import { activityDetail } from '@/api/businessCard'
import { Steps, Step } from 'element-ui'
import activeTemplate from './activityComponents/activeTemplate'
import activeConfig from './activityComponents/activeConfig'
import previewActive from './activityComponents/previewActive'
import { templateComKey, templateDefaultData } from './templateCom/templateConfig'
import templateActiveConfig from './templateCom/templateActiveConfig'


export default {
  name: 'createActivity',
  components: { Steps, Step, activeTemplate, activeConfig, templateActiveConfig, previewActive },
  data() {
    return {
      // 编辑模式
      editMode: false,
      currentStep: 1,
      previewActiveInfo: {},
      stepConfig: {
        0: ['基础信息', '选择模版', '配置内容', '发布活动'],
        1: ['基础信息', '选择模版', '编辑内容', '发布活动']
      },
      activityInfo: {
        startTime: new Date()
      },
      pageLoad: false
    }
  },
  created() {
    /**
     * 当前页面的模式
     * 1 编辑
     * 0 创建
     */
    this.editMode = this.$route.query.editMode || 0
    if (this.editMode) {
      this.initEditor()
    } else {
      this.initCreate()
    }
  },
  methods: {
    // 初始化编辑模式
    initEditor() {
      this.pageLoad = true
      this.modeActivity = this.$route.query.id
      activityDetail({
        activityId: this.modeActivity
      }).then(res => {
        this.pageLoad = false
        this.activityInfo = {
          editMode: this.editMode,
          ...res.data,
          templateComKey: templateComKey[res.data.templateCode]
        }
        this.currentStep = 3
      }).finally(() => {
        this.pageLoad = false
      })
    },
    // 初始化创建模式
    initCreate() {
      this.editMode = 0
      this.currentStep = 1
    },
    // 打开模版
    openTemplate() {
      if (!this.activityInfo.activityName) {
        this.$message.warning('请输入活动名称')
        return
      }
      if (!this.activityInfo.startTime || !this.activityInfo.endTime) {
        this.$message.warning('请选择活动时间')
        return
      }

      this.currentStep = 2
    },
    // 选择模版
    selectedTemplate(item) {
      this.currentStep = 3
      let defaultTemplate = {}
      // 初始化默认配置
      defaultTemplate = JSON.parse(JSON.stringify(templateDefaultData[[item.templateCode]]))
      this.activityInfo = {
        ...this.activityInfo,
        ...item,
        defaultTemplate,
        templateComKey: templateComKey[item.templateCode],
        editMode: this.editMode
      }

    },

    // 活动创建完成
    activeCall(data) {

      /**
       * status
       * 1 重新选择模板
       * 3 创建完成 进入预览
       */
      this.currentStep = parseInt(data.status)
      this.previewActiveInfo = data.info
    },

    // 创建新的活动
    createActive() {
      this.currentStep = 1
    }
  }
};
</script>

<style lang="scss" scoped>
  .create-activity{
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 12px 0 12px 12px;
    .basic-info{
      width: 100%;
      height: calc(100% - 102px);
      background: #fff;
      padding: 24px 12px;
      border-radius: 4px;
      margin-top: 12px;
      box-shadow: $cardShadow;
      text-align: center;
      .template-base-config{
        width: 566px;
        margin: 0 auto;
        ::v-deep{
          .g-ui-layout{
            margin-bottom: 24px;
          }
        }
      }
      ::v-deep{
        .el-button{
          width: 120px;
          margin-top: 12px;
        }
      }
    }
    .active-step{
      width: calc(100% - 12px);
      background: #fff;
      border-radius: 6px;
      box-shadow: $cardShadow;
      padding: 15px;
      margin-right: 12px;
      box-sizing: border-box;
      ::v-deep {
        .el-step{
          .is-finish {
            .is-text{
              background: #409EFF;
              border-color: #409EFF;
              color: #fff;
            }
          }
          .el-step__description{
            font-size: 14px;
            font-weight: 700;
          }
        }
        .el-step__main {
          margin-top: 16px;
        }
      }
    }
  }
</style>
