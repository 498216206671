<!--模板组件-->
<template>
  <div class="active-template">

    <div v-for="(item, index) in templateOption" :key="index" class="card" :class="{'show-view': item.showFlag, 'show-disabled': item.state !== '10'}" @mouseover="cardShow(item, true)" @mouseout="cardShow(item, false)">
      <div v-if="item.state !== '10'" class="disabled-use">
        <div class="iconfont icon-Shape1">
          <span class="use-text-des">{{item.stateDesc}}</span>
        </div>
      </div>
      <img class="card-img" :src="item.coverImg" />
      <div class="card-content">
        <div class="make" @click="startMake(item)">
          <span class="btn-inner">立即制作</span>
          <div class="circular1"></div>
          <div class="circular2"></div>
        </div>
        <div class="content-main">
          <p class="card-name">{{item.templateTitle}}</p>
          <p class="card-des">{{item.templateDesc}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { activityTemplatePage } from '@/api/businessCard'
export default {
  name: 'active-template',
  data() {
    return {
      templateOption: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      activityTemplatePage().then(res => {
        if (res.data.list) {
          res.data.list.forEach(v => {
            v.showFlag = false
          })
        }
        this.templateOption = res.data.list
      })
    },

    // 展示
    cardShow(item, flag) {
      if (item.state !== '10') return
      item.showFlag = flag
    },

    // 开始制作
    startMake(item) {
      this.$emit('startMake', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  .active-template{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    .card{
      margin-bottom: 20px;
      width: 207px;
      height: 446px;
      border: 1px #f8f8f8 solid;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
      border-radius: 6px;
      transition: all .3s;
      cursor: pointer;
      margin-right: 16px;
      overflow: hidden;
      position: relative;
      .disabled-use{
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(167, 167, 167, .4);
        z-index: 999;
        padding: 0 20px;
        box-sizing: border-box;
        text-align: right;
        .icon-Shape1{
          font-size: 40px;
          color: #dedede;
          position: relative;
          transform: rotate(30deg) translate(4px, -40px);
        }
        .use-text-des{
          display: inline;
          font-weight: bold;
          font-size: 12px;
          position: absolute;
          width: 100%;
          right: 8px;
          top: 22px;
          z-index: 1;
          color: #333;
        }
      }
      .circular1, .circular2{
        background:#409EFF;
        position: absolute;
        top: 50%;
        top:0\9;
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.6s;
        opacity:0.5;
      }
      .circular1{
        left: -20px;
        transform: translate(-50%,-50%);
      }
      .circular2{
        right: -20px;
        transform: translate(50%,-50%);
      }
      .card-img{
        margin: 0;
        width: 100%;
        height: 100%;
      }
      .card-content{
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        transition: all .3s;
      }
      .content-main{
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        color: #fff;
        background: rgba(0,0,0,.6);
        font-size: 12px;
        line-height: 18px;
        padding: 4px;
        box-sizing: border-box;
        margin: 0 auto;
        transition: all .3s;
      }
      .card-name{
        width: 100%;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .card-des{
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .show-view{
      transform: translate3d(0, -8px, 0);
      box-shadow: 0 4px 10px rgba(0, 0, 0, .3);
      .card-content{
        background: rgba(0,0,0,.6);
      }
      .content-main{
        opacity: 0;
      }
    }
    .show-disabled{
      cursor: no-drop;
    }
  }

  .make{
    border-radius: 6px;
    position:absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    border:1px solid #409EFF;
    z-index: 2;
    color: #666;
    overflow: hidden;
    transition: all .3s;
    opacity: 0;
    .btn-inner{
      position: relative;
      z-index: 2;
      display: block;
      width: 100%;
    }
  }
  .card{
    :hover{
      .make{
        opacity: 1;
        color:#fff;
        border-color:#409EFF;
      }
      .circular1{
        animation:criss-cross-left .8s both;
        width:375px\9;
        height:375px\9;
        opacity:1;
      }
      .circular2{
        animation:criss-cross-right .8s both;
        width:375px\9;
        height:375px\9;
        opacity:1;
      }
    }
  }
  @-webkit-keyframes criss-cross-left {
    0% {
      left:-20px
    }
    50% {
      left:50%;width:20px;height:20px;
    }
    100% {
      left:50%;width:375px;height:375px;
    }
  }
  @keyframes criss-cross-left {
    0% {
      left:-20px
    }
    50% {
      left:50%; width:20px;height:20px;
    }
    100% {
      left:50%;width:375px;height:375px;
    }
  }
  @-webkit-keyframes criss-cross-right {
    0% {
      right:-20px
    }
    50% {
      right:50%;width:20px;height:20px;
    }
    100% {
      right:50%;width:375px;height:375px;
    }
  }
  @keyframes criss-cross-right {
    0% {
      right:-20px
    }
    50% {
      right:50%;width:20px;height:20px;
    }
    100% {
      right:50%;width:375px;height:375px;
    }
  }
</style>
