<template>
  <div class="preview-active">

    <div class="preview-content" v-loading="loading">

      <p class="des">打开微信扫一扫预览活动</p>

      <img class="mini-img" :src="mpImg" alt="">

    </div>

    <div class="footer">
      <el-button type="primary" @click="createActive">继续创建</el-button>
      <el-button @click="activeTable">返回活动列表</el-button>
    </div>

  </div>
</template>

<script>
import { activityQrCode } from '@/api/businessCard'
export default {
  name: 'preview-active',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      mpImg: '',
      loading: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {

    // 获取二维码图片地址
    init() {
      this.loading = true
      activityQrCode({
        activityId: this.info.activityId
      }).then(res => {
        this.mpImg = res.data.url
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 200)
      })
    },

    // 继续创建活动
    createActive() {
      this.$router.push({
        path: 'create'
      });
      this.$emit('createActive')
    },

    // 前往活动列表
    activeTable() {
      this.$router.push({
        path: 'ctivity'
      })
    },

  }
}
</script>

<style lang="scss" scoped>
  .preview-active{
    width: calc(100% - 12px);
    height: calc(100% - 105px);
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    margin: 12px 12px 0 0;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .preview-content{
      height: calc(100% - 60px);
      overflow-y: auto;
      padding-bottom: 40px;
    }
    .title{
      font-size: 16px;
      color: #409EFF;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .des{
      font-size: 14px;
      color: #7d7d7d;
      font-weight: bold;
      margin-top: 50px;
    }
    .mini-img{
      display: block;
      width: 240px;
      height: 240px;
      margin: 30px auto;
    }
    .down-img{
      margin-top: 20px;
    }
    .footer{
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border-top: 1px #f8f8f8 solid;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
    }
  }
</style>
